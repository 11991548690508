<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-col md="6">
    <div class="bg-gray">
      <div
        v-for="(item, index) in config"
        :id="String(index)"
        :key="index"
        class="mb-1"
      >
        <b-form-group :label="`Key ${index + 1}`" label-cols-md="3">
          <b-form class="repeater-form" @submit.prevent="repeateAgain()">
            <b-row>
              <b-col md="4">
                <b-form-input v-model="config[index].key" placeholder="key" />
              </b-col>
              <b-col md="7">
                <b-form-input
                  v-model="config[index].value"
                  placeholder="value"
                />
              </b-col>
              <b-col md="1">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-25" />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-form-group>
      </div>

      <b-form-group label-cols-md="3" label="">
        <div
          v-if="config[0].value.length < 100"
          class="fade-browse"
          @click="repeateAgain()"
        >
          <feather-icon icon="PlusCircleIcon" class="mr-25" />
          More attribute ({{ config.length }}/100)
        </div>
      </b-form-group>
    </div>

    <div class="d-flex align-items-center justify-content-center mt-2">
      <b-button
        variant="primary"
        type="submit"
        class="ml-2"
        @click.prevent="handlerCreate"
      >
        Submit
      </b-button>
    </div>
  </b-col>
</template>
<script>
/* eslint-disable array-callback-return */
import {
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      config: [
        {
          key: '',
          value: '',
        },
      ],
      key: 'buso_client_page_config',
      isExistSetting: false,
    }
  },
  mounted() {
    this.loadConfig()
  },
  methods: {
    async loadConfig() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_setting/${
            this.key
          }?template_id=${JSON.parse(localStorage.getItem('templateID')).id}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.isExistSetting = true
            this.config = []
            JSON.parse(res.data.data.value).map(val => {
              this.config.push(val)
            })
          } else {
            this.isExistSetting = false
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerCreate() {
      try {
        const params = {
          template_id: JSON.parse(localStorage.getItem('templateID')).id,
          key: this.key,
          type: '0',
          value: JSON.stringify(this.config),
        }
        let res = null
        if (!this.isExistSetting) {
          res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/platform_settings`,
            params,
          )
        } else {
          res = await Request.put(
            this.$http,
            `${process.env.VUE_APP_API_URL}/platform_setting/${this.key}`,
            params,
          )
        }
        if (res.status === 200) {
          if (res.data.status) {
            this.isExistSetting = true
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'Success',
                text: String('Create success'),
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    repeateAgain() {
      this.config.push({
        key: '',
        value: '',
      })
    },
    removeItem(index) {
      this.config.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
button svg {
  margin-right: 0 !important;
}
</style>
